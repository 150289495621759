<template>
  <v-col cols="12" class="pt-0">
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <v-btn class="pa-0 font-weight-regular" style="text-decoration: underline" text v-on="on">
          Regulamento completo da promoção
        </v-btn>
      </template>

      <v-card>
        <v-app-bar dark style="background-color: #0ad18b; color: #ffffff">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-app-bar-title>Regulamento</v-app-bar-title>
        </v-app-bar>

        <v-card-text>

          <body>
            <p class="c3 mt-5">
              <span class="c7 c1">Regulamento - Cupons de Desconto e Programa de
                Indica&ccedil;&atilde;o do Simplificador</span>
            </p>
            <p class="c3"><span class="c7 c1">1. Diretrizes Gerais </span></p>

            <p class="c3">
              <span class="c1">1.1.</span><span class="c4">&nbsp;O </span><span class="c1">SIMPLIFICADOR
                ADMINISTRA&Ccedil;&Atilde;O E TECNOLOGIA
                LTDA</span><span class="c4">., neste regulamento denominado &quot;</span><span
                class="c1">Simplificador</span><span class="c4">&quot;, com sede na Rua Coronel Artur God&oacute;i, 79 -
                Vila
                Mariana - S&atilde;o Paulo/SP, inscrito no CNPJ sob o n&ordm;
                26.932.593/0001-18, estabelece o presente regulamento do
                programa denominado &ldquo;</span><span class="c1">CUPONS DE DESCONTO e PROGRAMA DE
                INDICA&Ccedil;&Atilde;O
                SIMPLIFICADOR</span><span class="c2">&rdquo;, o qual se reger&aacute; nos termos e
                condi&ccedil;&otilde;es a seguir estipuladas.
              </span>
            </p>

            <p class="c3">
              <span class="c4">Os </span><span class="c1">CUPONS DE DESCONTO e PROGRAMA DE INDICA&Ccedil;&Atilde;O
                SIMPLIFICADOR</span><span class="c2">, objetos do presente regulamento, s&atilde;o de
                responsabilidade e propriedade do SIMPLIFICADOR
                ADMINISTRA&Ccedil;&Atilde;O E TECNOLOGIA LTDA.
              </span>
            </p>

            <p class="c3">
              <span class="c7 c1">2. Vig&ecirc;ncia e Participantes </span>
            </p>

            <p class="c3">
              <span class="c1">2.1.</span><span class="c4">&nbsp;Os </span><span class="c1">CUPONS DE DESCONTO e
                PROGRAMA DE
                INDICA&Ccedil;&Atilde;O
                SIMPLIFICADOR</span><span class="c2">&nbsp;n&atilde;o dependem de sorteio, concurso ou
                opera&ccedil;&atilde;o semelhante e est&atilde;o em conformidade
                com as disposi&ccedil;&otilde;es legais vigentes.
              </span>
            </p>

            <p class="c3">
              <span class="c1">2.2.</span><span class="c2">&nbsp;O programa tem vig&ecirc;ncia por tempo indeterminado.
              </span>
            </p>
            <p class="c3">
              <span class="c1">NOTA 01</span><span class="c4">. Os </span><span class="c1">CUPONS DE DESCONTO e PROGRAMA
                DE
                INDICA&Ccedil;&Atilde;O
                SIMPLIFICADOR</span><span class="c4">&nbsp;poder&atilde;o ser extintos ou alterados a qualquer tempo
                a crit&eacute;rio exclusivo do </span><span class="c1">Simplificador</span><span class="c2">. Havendo
                quaisquer altera&ccedil;&otilde;es, ser&atilde;o
                realizadas comunica&ccedil;&otilde;es pr&eacute;vias aos
                participantes, nestes casos, ser&atilde;o respeitados todos os
                direitos adquiridos pelas pessoas que fizeram
                indica&ccedil;&otilde;es at&eacute; a data da
                extin&ccedil;&atilde;o ou altera&ccedil;&otilde;es.
              </span>
            </p>

            <p class="c3"><span class="c7 c1">3. Quem pode participar </span></p>

            <p class="c3">
              <span class="c1">3.1.</span><span class="c4">&nbsp;Os </span><span class="c1">Cupons de
                Desconto</span><span class="c4">&nbsp;podem ser concedidos por parceiros do Simplificador, por
                meio de palavra chave que, utilizada no momento da
                contrata&ccedil;&atilde;o.</span>
            </p>

            <p class="c3">
              <span class="c1">3.2.</span><span class="c4">&nbsp;O </span><span class="c1">Programa de
                Indica&ccedil;&atilde;o</span><span class="c4">&nbsp;se aplica a clientes do </span><span
                class="c1">Simplificador</span><span class="c4">&nbsp;que fa&ccedil;am indica&ccedil;&otilde;es de
                outras
                pessoas para tamb&eacute;m se tornarem clientes do </span><span class="c1">Simplificador</span><span
                class="c2">. &Eacute; considerado cliente a pessoa que possui uma conta
                ativa e adimplente (sem pend&ecirc;ncias financeiras junto ao
                Simplificador).
              </span>
            </p>

            <p class="c3">
              <span class="c1">3.2.1.</span><span class="c4">&nbsp;&Eacute; considerado cliente a(s) pessoa(s)
                vinculada(s)
                a uma Pessoa Jur&iacute;dica constitu&iacute;da com CNPJ e que
                seja contratante dos servi&ccedil;os do </span><span class="c1">Simplificador</span><span
                class="c2">.</span>
            </p>

            <p class="c3">
              <span class="c1">3.2.2.</span><span class="c2">&nbsp;Caso haja mais de uma pessoa f&iacute;sica natural
                s&oacute;cia de uma Pessoa Jur&iacute;dica, todas as pessoas
                f&iacute;sicas poder&atilde;o indicar. Os benef&iacute;cios do
                programa ser&atilde;o destinados ao CNPJ em que est&atilde;o
                vinculados como cliente.</span>
            </p>

            <p class="c3">
              <span class="c1">3.2.3.</span><span class="c4">&nbsp;Caso haja uma pessoa f&iacute;sica s&oacute;cia de
                mais
                de uma Pessoa Jur&iacute;dica, esta dever&aacute; comunicar
                formalmente por e-mail ao </span><span class="c1">Simplificador </span><span class="c4">a qual CNPJ
                dever&atilde;o ser destinados os benef&iacute;cios
                do </span><span class="c1">Programa de Indica&ccedil;&atilde;o.</span><span class="c4">.</span>
            </p>

            <p class="c3">
              <span class="c7 c1">4. Como funcionam os Cupons de Desconto e o Programa de
                indica&ccedil;&atilde;o
              </span>
            </p>

            <p class="c3">
              <span class="c1">4.1. </span><span class="c4">Os </span><span class="c1">Cupons de Desconto</span><span
                class="c2">&nbsp;podem ser concedidos por parceiros do Simplificador, por
                meio de palavra chave que, utilizada pelo contratante no momento
                da contrata&ccedil;&atilde;o, ir&aacute; conceder descontos
                espec&iacute;ficos, de acordo com a parceria em quest&atilde;o.
                Os descontos podem ser &uacute;nicos (aplicados somente uma
                vez), ou recorrentes, em todas as mensalidades
                subsequentes.</span>
            </p>

            <p class="c3">
              <span class="c1">4.2.</span><span class="c4">&nbsp;</span><span class="c1">No programa de
                indica&ccedil;&atilde;o,</span><span class="c4">&nbsp;o cliente do </span><span
                class="c1">Simplificador</span><span class="c4">&nbsp;indica uma pessoa para se tornar cliente por meio
                de um
                cupom personalizado a ser inserido no momento da
                contrata&ccedil;&atilde;o (carrinho de compras) do </span><span class="c1">Simplificador</span><span
                class="c4">.</span>
            </p>

            <p class="c3">
              <span class="c1 c6">4.2.1.</span><span class="c0">&nbsp;Se a indica&ccedil;&atilde;o efetivar a
                contrata&ccedil;&atilde;o do </span><span class="c1 c6">Simplificador </span><span class="c2 c6">e for
                realizada a compensa&ccedil;&atilde;o do pagamento, o
                indicador receber&aacute; um desconto de R$ 75,00 (cinquenta
                reais); por cada pessoa que se tornar cliente ativo e
                pagante.</span>
            </p>
            <p class="c3 c5"><span class="c2 c6"></span></p>
            <p class="c3">
              <span class="c1 c6">4.2.2.</span><span class="c0">&nbsp;O novo contratante, vindo por meio do
                &ldquo;Programa de
                indica&ccedil;&atilde;o&rdquo;, tamb&eacute;m receber&aacute; um
                desconto em sua primeira mensalidade, correspondente a 10% do
                valor mensal contratado de acordo com o plano escolhido e
                vigente &agrave; &eacute;poca da contrata&ccedil;&atilde;o do </span><span
                class="c7 c1 c6">Simplificador.</span> por um ano
            </p>
            <p class="c3 c5"><span class="c2 c8"></span></p>
            <p class="c3">
              <span class="c1">4.2.3.</span><span class="c2">&nbsp;O desconto recebido pelo indicador, ser&aacute;
                aplicado
                em forma de abatimento no valor da(s) mensalidade(s) ainda a
                serem cobradas. Uma vez que devem ser observados os ciclos de
                cobran&ccedil;a mensal.</span>
            </p>

            <p class="c3">
              <span class="c1">4.2.4.</span><span class="c4">&nbsp;Havendo muitas indica&ccedil;&otilde;es
                v&aacute;lidas
                para abatimento de mensalidades, o cliente receber&aacute;
                descontos em suas mensalidades futuras, com o valor sendo
                abatido, sem limite de prazo. A t&iacute;tulo de
                exemplifica&ccedil;&atilde;o: havendo 20 novos clientes
                provenientes da indica&ccedil;&atilde;o de um cliente do </span><span
                class="c1">Simplificador</span><span class="c4">, este ter&aacute; abatimento de R$1.000,00 (hum mil
                reais),
                sempre sendo aplicadas no decorrer do prazo que o indicador
                estiver contratando o </span><span class="c1">Simplificador</span><span class="c2">.</span>
            </p>

            <p class="c3">
              <span class="c1">4.2.5.</span><span class="c4">&nbsp;Caso o indicador resolva cessar a
                utiliza&ccedil;&atilde;o dos servi&ccedil;os do </span><span class="c1">Simplificador</span><span
                class="c4">&nbsp;antes de ter se beneficiado de todos os descontos que
                vier a ter recebido em virtude de indica&ccedil;&otilde;es,
                perder&aacute; direito de desconto e n&atilde;o haver&aacute;
                compensa&ccedil;&atilde;o financeira de qualquer tipo pelas
                indica&ccedil;&otilde;es realizadas. Estas
                indica&ccedil;&otilde;es n&atilde;o ficar&atilde;o acumuladas
                caso o indicador volte a contratar os servi&ccedil;os do </span><span
                class="c1">Simplificador</span><span class="c4">.</span><span class="c2">&nbsp;</span>
            </p>

            <p class="c3">
              <span class="c1">4.2.6</span><span class="c2">. Caso o cliente possua descontos provenientes do
                &ldquo;programa&rdquo;, ter&aacute; o benef&iacute;cio cancelado
                caso permane&ccedil;a inadimplente por per&iacute;odo igual ou
                superior a 3 (tr&ecirc;s) meses.</span>
            </p>

            <p class="c3"><span class="c7 c1">5. Como Participar</span></p>

            <p class="c3">
              <span class="c1">5.1. </span><span class="c4">Para utilizar os </span><span class="c1">Cupons de
                Desconto</span><span class="c2">, basta inserir o Cupom recebido por meio de um de nosso
                parceiros no carrinho de compras no momento da
                contrata&ccedil;&atilde;o.</span>
            </p>
            <p class="c3"><span class="c1 c7">&nbsp;</span></p>
            <p class="c3">
              <span class="c1">5.2..Para utilizar o Programa de Indica&ccedil;&atilde;o,</span><span
                class="c2">&nbsp;cada
                cliente pode indicar quantos clientes quiser, basta
                indicar o cupom para o novo contratante.</span>
            </p>

            <p class="c3"><span class="c7 c1">6. Pr&ecirc;mio Vigente </span></p>

            <p class="c3">
              <span class="c1">6.1. </span><span class="c4">O pr&ecirc;mio dos </span><span class="c1">Cupons de
                Desconto</span><span class="c2">&nbsp;podem variar de acordo com a parceria e podem ser
                suspensos a qualquer momento, sem aviso pr&eacute;vio, por parte
                do Simplificador.</span>
            </p>
            <p class="c3 c5"><span class="c7 c1 c6"></span></p>
            <p class="c3">
              <span class="c1 c6">6.2.</span><span class="c0">&nbsp;O pr&ecirc;mio do </span><span
                class="c1 c6">Programa de
                Indica&ccedil;&atilde;o</span><span class="c2 c6">, vigente ao cliente ser&aacute;: Desconto de R$ 75,00
                para
                o
                atual cliente, a ser aplicado nas faturas dos meses subsequentes
                ao da indica&ccedil;&atilde;o.</span>
            </p>
            <p class="c3 c5"><span class="c7 c1 c6"></span></p>
            <p class="c3">
              <span class="c1 c6">6.3.</span><span class="c0">&nbsp; </span><span class="c1 c6">O pr&ecirc;mio do
                Programa de
                Indica&ccedil;&atilde;o</span><span class="c2 c6">, vigente ao novo contratante ser&aacute;: Desconto de
                30%
                na
                mensalidade de contrata&ccedil;&atilde;o.</span>
            </p>

            <p class="c3">
              <span class="c1">NOTA 02</span><span class="c4">. O </span><span class="c1">Simplificador</span><span
                class="c2">&nbsp;se reserva ao direito de poder alterar o pr&ecirc;mio a
                qualquer momento, por mera liberalidade, sem
                motiva&ccedil;&atilde;o justificada e sem aviso pr&eacute;vio.
              </span>
            </p>

            <p class="c3">
              <span class="c7 c1">7. Prazo para entrega do Pr&ecirc;mio </span>
            </p>

            <p class="c3">
              <span class="c1">7.1.</span><span class="c2">&nbsp;O cr&eacute;dito do pr&ecirc;mio pela
                indica&ccedil;&atilde;o pode entrar em at&eacute; 60 (sessenta)
                dias ap&oacute;s a compensa&ccedil;&atilde;o do pagamento do ato
                de contrata&ccedil;&atilde;o do novo cliente que foi indicado. O
                desconto referente &agrave; indica&ccedil;&atilde;o,
                constar&aacute; discriminado na fatura mensal para facilitar o
                controle das indica&ccedil;&otilde;es realizadas.</span>
            </p>

            <p class="c3">
              <span class="c7 c1">8. Informa&ccedil;&otilde;es Importantes
              </span>
            </p>

            <p class="c3">
              <span class="c1 c6">8.1. </span><span class="c2 c6">Por se tratar de um servi&ccedil;o profissional
                regulamentado
                prestado recorrentemente, a fatura nunca vir&aacute; com valor
                zerado. Por isso, caso o desconto mensal ultrapasse o valor da
                mensalidade, o cliente ir&aacute; pagar uma mensalidade
                m&iacute;nima, no valor de R$9,90, e o restante dos descontos
                ser&atilde;o aplicados em suas pr&oacute;ximas faturas.
              </span>
            </p>

            <p class="c3">
              <span class="c1">8.2.</span><span class="c4">&nbsp;As premia&ccedil;&otilde;es ser&atilde;o v&aacute;lidas
                por adquirente, que quer dizer, por Pessoa Jur&iacute;dica que
                contratar o </span><span class="c1">Simplificador</span><span class="c4">, e n&atilde;o por quantidade
                de
                s&oacute;cios integrantes no
                CNPJ que contratar o </span><span class="c1">Simplificador</span><span class="c2">. (Ex. Se Maria
                indicou
                Jos&eacute; e Jos&eacute; tem 3
                s&oacute;cios no mesmo CNPJ, Maria ter&aacute; direito a apenas
                1 pr&ecirc;mio).
              </span>
            </p>

            <p class="c3">
              <span class="c1">8.3.</span><span class="c2">&nbsp;Cada pessoa ou parceiro poder&aacute; indicar
                at&eacute;
                quantos contatos quiser.</span>
            </p>

            <p class="c3">
              <span class="c1">8.4.</span><span class="c4">&nbsp;A Promo&ccedil;&atilde;o do &ldquo;</span><span
                class="c1">Programa de Indica&ccedil;&atilde;o Simplificador</span><span class="c4">&rdquo;, n&atilde;o
                est&aacute; vinculada a qualquer outra
                promo&ccedil;&atilde;o do </span><span class="c1">Simplificador</span><span class="c2">&nbsp;em vigor
                durante
                seu prazo e, portanto, seus
                benef&iacute;cios n&atilde;o s&atilde;o cumulativos.
              </span>
            </p>

            <p class="c3">
              <span class="c1">9. Disposi&ccedil;&otilde;es finais</span><span class="c2">&nbsp;</span>
            </p>

            <p class="c3">
              <span class="c1">9.1.</span><span class="c4">&nbsp;O Indicador receber&aacute; o pr&ecirc;mio em forma de
                desconto nas faturas futuras que tiver com o </span><span class="c1">Simplificador.</span>
            </p>

            <p class="c3">
              <span class="c1">9.2.</span><span class="c2">&nbsp;Os participantes estar&atilde;o vinculados ao
                regulamento, qualquer quest&atilde;o dever&aacute; ser dirigida
                no foro da S&atilde;o Paulo/SP.
              </span>
            </p>

            <p class="c3">
              <span class="c1">9.3.</span><span class="c4">&nbsp;Quaisquer d&uacute;vidas, diverg&ecirc;ncias ou
                situa&ccedil;&otilde;es n&atilde;o previstas neste regulamento,
                ser&atilde;o analisadas e decididas unilateralmente pela
                diretoria do </span><span class="c1">Simplificador</span><span class="c2">. </span>
            </p>

            <p class="c3">
              <span class="c1">9.4.</span><span class="c2">&nbsp;A promo&ccedil;&atilde;o independe de qualquer
                modalidade
                de sorteio, vale-brinde, concurso ou opera&ccedil;&atilde;o
                assemelhada, n&atilde;o estando, portanto, sujeita &agrave;
                autoriza&ccedil;&atilde;o pr&eacute;via estabelecida no artigo
                1o da Lei no 5.768/71.
              </span>
            </p>

            <p class="c3">
              <span class="c1">9.5.</span><span class="c2">&nbsp;O cliente interessado em participar da
                indica&ccedil;&atilde;o, deve ler o regulamento,
                dispon&iacute;vel publicamente a quem interessar. E deve ter
                plena ci&ecirc;ncia dos termos constantes no presente
                regulamento, concordando expressamente com todos seus termos,
                cl&aacute;usulas e condi&ccedil;&otilde;es.</span>
            </p>
          </body>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style type="text/css">
ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Roboto';
  font-style: normal;
}

.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c0 {
  background-color: #ffffff;
  font-size: 10pt;
  font-family: 'Roboto';
  font-weight: 400;
}

.c7 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c4 {
  font-size: 10pt;
  font-family: 'Roboto';
  font-weight: 400;
}

.c1 {
  font-size: 10pt;
  font-family: 'Roboto';
  font-weight: 700;
}

.c9 {
  max-width: 481.9pt;
  padding: 12.7pt 20.7pt;
}

.c6 {
  background-color: #ffffff;
}

.c8 {
  background-color: #ffff00;
}

.c5 {
  height: 11pt;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}

h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
